import { FormType } from '@/constant/form';
import { formatKeyValueObject } from '@/utils/base';

const STAFF_DICTIONARY = {
  WORK: 'WORK',
  RESIGN: 'RESIGN',
};
const STAFF_EMPTYPE = [{
  label: '正式工',
  value: 'REGULAR',
}, {
  label: '临时工',
  value: 'TEMPORARY',
}];
const STAFF_EMPTYPE_ENUM = formatKeyValueObject(STAFF_EMPTYPE);

const STAFF_TYPE = [{
  label: '在职',
  value: 'WORK',
}, {
  label: '离职',
  value: 'RESIGN',
}];
const STAFF_TYPE_ENUM = formatKeyValueObject(STAFF_TYPE);
export {
  STAFF_DICTIONARY, STAFF_EMPTYPE, STAFF_TYPE,
  STAFF_EMPTYPE_ENUM, STAFF_TYPE_ENUM,
};
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '员工工号',
  prop: 'staffCode',
  minWidth: 80,
}, {
  label: '员工姓名',
  prop: 'staffName',
  minWidth: 120,
}, {
  label: '岗位',
  prop: 'postName',
  minWidth: 120,
}, {
  label: '员工类型',
  prop: 'staffType',
  minWidth: 120,
  formatter: (row) => STAFF_EMPTYPE_ENUM[row.staffType],
}, {
  label: '状态',
  prop: 'status',
  minWidth: 120,
  formatter: (row) => STAFF_TYPE_ENUM[row.status],
}, {
  label: '联系电话',
  prop: 'telephone',
  minWidth: 120,
}, {
  label: '邮箱',
  prop: 'mail',
  minWidth: 120,
}, {
  label: '关联账号',
  prop: 'account',
  minWidth: 120,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 120,
}];

export const serchFields = [
  {
    label: '员工工号',
    prop: 'staffCode',
    component: FormType.INPUT,
  }, {
    label: '员工姓名',
    prop: 'staffName',
    component: FormType.INPUT,
  }, {
    label: '员工类型',
    prop: 'staffType',
    component: FormType.SELECT,
    options: STAFF_EMPTYPE,
  }, {
    label: '状态',
    prop: 'status',
    component: FormType.SELECT,
    options: STAFF_TYPE,
  },
];
