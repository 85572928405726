import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 员工 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11016
 */

export function getStaffPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff/page', data, {
    params,
  });
}
/**
 * @description: 员工 新增或编辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11014
 */
export function staffUpsert(data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff/upsert', data);
}
/**
 * @description: 导出员工管理
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11038
 */
export function staffExport(data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff/export', data, { fileName: '员工管理', extension: 'xlsx' });
}
/**
 * @description: 岗位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11278
 */
export function getPositionPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/position/page', data, { params });
}

/**
 * @description: 查询仓库绑定信息 /关联账号
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/13044
 */

export function warehouseUserBind() {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_user/bind/list', {});
}
