<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/人员管理/员工管理/[新建]'
          }"
          @click="handleAdd"
        >
          新建
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/人员管理/员工管理/[导入]'
          }"
        >
          导入
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/人员管理/员工管理/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleEdit(row)">
          编辑
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <edit-dialog ref="childEdit" @submitSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import EditDialog from './components/editDialog.vue';
import {
  TABLECOLUMN, STAFF_EMPTYPE, STAFF_TYPE, serchFields,
} from './fileds';
import { getStaffPage, staffExport } from './api';

export default {
  name: 'StorageEquipment',
  components: {
    EditDialog,
  },
  mixins: [loadingMixin],

  data() {
    return {
      serchFields,
      STAFF_EMPTYPE,
      STAFF_TYPE,
      formData: {
        staffCode: null,
        staffName: null,
        staffType: null,
        status: null,
      },
      TABLECOLUMN,
      tableData: [],
      loading: {
        getTableData: false,
        onExportStaff: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getStaffPage(pagination, this.formData);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    async handleExport() {
      staffExport();
    },
    handleAdd() {
      this.$refs.childEdit.init();
    },
    handleEdit(row) {
      this.$refs.childEdit.init(row);
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
